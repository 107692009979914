import React from "react";
import ReactDOM from "react-dom";

import { Box } from "@mui/material";
import CursorAnimation from "./CursorAnimation";

import ummmmmmmm from "./reggie.jpg";
import overlay from "./overlay.gif";

export default function Home() {
   return (
      <>
         <Box
            sx={{
               height: "100%",
               width: "100%",
               backgroundImage: `url(${overlay})`,
               backgroundRepeat: "repeat",
               backgroundSize: 40,
               backgroundPosition: "top left",
               position: "absolute",
               top: 0,
               left: 0,
            }}
         >
            <CursorAnimation />
         </Box>

         <Box
            sx={{
               height: "100%",
               width: "100%",
               backgroundImage: `url(${overlay})`,
               backgroundRepeat: "repeat",
               backgroundSize: 40,
               backgroundPosition: "top left",
               position: "absolute",
               top: 0,
               left: 0,
            }}
         />
         <Box
            sx={{
               height: "100vh",
               width: "100%",
               backgroundImage: `url(${ummmmmmmm})`,
               backgroundSize: "cover",
               backgroundPosition: "center center",
            }}
         />
      </>
   );
}
