import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";

import App from "./App";

// CONTEXT
import { ThemeProvider, createTheme } from "@mui/material/styles";

// THEME
const globalTheme = createTheme({
   palette: {
      mode: "dark",
      background: {
         default: "#222",
      },
   },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
   <ThemeProvider theme={globalTheme}>
      <App />
   </ThemeProvider>
);
