import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

import { Box } from "@mui/material";

import { useFollowPointer } from "./useFollowPointer";

const CursorAnimation = () => {
   const ref = useRef(null);
   const { x, y } = useFollowPointer(ref);

   return (
      <motion.div
         ref={ref}
         className='box'
         style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: "rgba(255,0,0,.2)",
            position: "absolute",
         }}
         animate={{ x, y }}
         transition={{ type: "spring", restDelta: 0.5 }}
      />
   );
};

export default CursorAnimation;
