import React from "react";
import ReactDOM from "react-dom";

import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import Home from "./Home";

export default function App() {
   return (
      <Router>
         <Routes>
            <Route path='/*' element={<Home />} />
         </Routes>
      </Router>
   );
}
